exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-cancellation-refund-js": () => import("./../../../src/pages/cancellation-refund.js" /* webpackChunkName: "component---src-pages-cancellation-refund-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-products-verve-icms-jsx": () => import("./../../../src/pages/our-products/verve-icms.jsx" /* webpackChunkName: "component---src-pages-our-products-verve-icms-jsx" */),
  "component---src-pages-our-products-verve-pos-jsx": () => import("./../../../src/pages/our-products/verve-pos.jsx" /* webpackChunkName: "component---src-pages-our-products-verve-pos-jsx" */),
  "component---src-pages-our-products-verve-suit-jsx": () => import("./../../../src/pages/our-products/verve-suit.jsx" /* webpackChunkName: "component---src-pages-our-products-verve-suit-jsx" */),
  "component---src-pages-our-team-jsx": () => import("./../../../src/pages/our-team.jsx" /* webpackChunkName: "component---src-pages-our-team-jsx" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-about-us-js": () => import("./../../../src/pages/resources/about-us.js" /* webpackChunkName: "component---src-pages-resources-about-us-js" */),
  "component---src-pages-resources-contact-js": () => import("./../../../src/pages/resources/contact.js" /* webpackChunkName: "component---src-pages-resources-contact-js" */),
  "component---src-pages-resources-customer-success-story-jsx": () => import("./../../../src/pages/resources/customer-success-story.jsx" /* webpackChunkName: "component---src-pages-resources-customer-success-story-jsx" */),
  "component---src-pages-resources-faq-jsx": () => import("./../../../src/pages/resources/faq.jsx" /* webpackChunkName: "component---src-pages-resources-faq-jsx" */),
  "component---src-pages-resources-our-team-js": () => import("./../../../src/pages/resources/our-team.js" /* webpackChunkName: "component---src-pages-resources-our-team-js" */),
  "component---src-pages-resources-portfolio-js": () => import("./../../../src/pages/resources/portfolio.js" /* webpackChunkName: "component---src-pages-resources-portfolio-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-seo-js": () => import("./../../../src/pages/services/seo.js" /* webpackChunkName: "component---src-pages-services-seo-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

